<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('EducationPrograms')"
      :subtitle="$t('Structure')"
      icon="mdi-school-outline"
      :header-action="openNewInstitute"
      :header-action-text="$t('wsu.education.education_program.add')"
      :no-content-condition="items.length === 0"
      :no-content-text="$t('wsu.education.education_program.no_content')"
      :no-content-button-action="openNewInstitute"
      :no-content-button-text="$t('wsu.education.education_program.add')"
      fill-height
  >

    <template #default >
      <ws-data-table
          :items="itemsFiltered"
          :row-action="selectEntity"
          :headers="headers"
          :context-actions-select="contextActionsSelect"
          @itemSelect="selectedItem = $event"
          class="mt-5"
      >
        <template #item.name="{item}">
          <h5 :style="`color : ${wsACCENT}`">{{ item.name }}</h5>
          <h5 class="font-weight-regular">{{ item.department_name }}</h5>
        </template>

        <template #item.degrees="{item}">
          <div>
            <h5   v-for="degree in item.degrees"
                  :key="degree.alias">{{ degree.name }}
            </h5>
          </div>
        </template>

        <template #item.disciplines_count="{item}">
          <h5 class="text-center">{{ item.disciplines_count }}</h5>
        </template>

        <template #item.education_programs_count="{item}">
          <h5 class="text-center">{{ item.education_programs_count }}</h5>
        </template>

        <template #item.study_plans_count="{item}">
          <h5 class="text-center">{{ item.study_plans_count }}</h5>
        </template>

      </ws-data-table>
    </template>

    <template #dialog>
      <!--  AddEdit Spcecilaty -->
      <ws-dialog
          v-model="displayDialog"
          :title="$t(!editEntity ? 'wsu.education.education_program.add' : 'wsu.education.education_program.edit')"
          @save="addEntity"
          @delete="deleteEntity"
          :confirm-delete-code="selectedItem ? selectedItem.uuid : null"
          :show-delete="editEntity"
      >

        <ws-text-field
            v-model="entityData.work_name"
            :label="$t('wsu.education.education_program.name')"
            :placeholder="$t('wsu.education.education_program.work_name.placeholder')"
            clearable
        />

        <ws-select
            class="mt-6 mb-6"
            v-model="entityData.study_field"
            :items="studyFieldsSelect"
            :label="$t('FieldOfStudy')"
            :placeholder="$t('wsu.structure.specialty.field_of_study.placeholder')"
            clearable
            autocomplete
        />

        <ws-select
            class="mt-6 mb-6"
            v-model="entityData.specialty_id"
            :items="specialtiesSelectFiltered"
            :label="$t('Specialty')"
            :placeholder="$t('wsu.structure.specialty.choose')"
            clearable
            autocomplete
        />

        <v-expand-transition>
          <ws-select
              v-if="specializationsSelectFiltered.length > 0"
              v-model="entityData.specialization_id"
              :items="specializationsSlectFiltered"
              :label="$t('SubjectSpecialty')"
              :placeholder="$t('wsu.structure.subject_specialty.choose')"
              clearable

          />
        </v-expand-transition>

        <ws-select
            v-model="entityData.degree_id"
            :items="degreesSelect"
            :label="$t('Degrees')"
            :placeholder="$t('wsu.structure.degree.choose')"
            clearable
            multiple
        />

        <v-expand-transition>
          <ws-select
              v-if="departmentsSelect.length > 0"
              v-model="entityData.department_id"
              :items="departmentsSelect"
              :label="$t('Department')"
              :placeholder="$t('wsu.structure.department.choose')"
              clearable
          />
        </v-expand-transition>

      </ws-dialog>
      <!--  Delete Faculty-->
      <ws-dialog
          v-if="displayDeleteDialog"
          v-model="displayDeleteDialog"
          @delete="deleteEntity"
          :title="$t('wsu.structure.specialty.delete')"
          display-confirm-delete
          :confirm-delete-code="selectedItem ? selectedItem.uuid : null"
          close-delete
      />

    </template>

  </sub-page>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "specialties",
  data() {
    return {
      items : [],
      departmentsSelect : [],
      specialtiesSelect : [],
      specializationsSelect : [],
      degreesSelect : [],
      studyFieldsSelect : [],
      displayDialog : false,
      entityData : {},
      selectedItem : {},
      displayDeleteDialog : false,
      editEntity : false,
      departmentError : false,
    }
  },
  computed : {
    ...mapState('avalon' , ['selectedLang']),
    ...mapState('structure' , ['selectedEntityType' , 'selectedEntityId' ]),

    specialtiesSelectFiltered() {
      let items = this.specialtiesSelect
      if ( this.entityData.study_field ) {
        items = items.filter(el=>el.study_field === this.entityData.study_field )
      }
      return items
    },
    headers() {
      return [
        { text : this.$t('Name')           , value : 'name'                                 },
        { text : this.$t('Degree')         , value : 'degrees'                     , width: 10 },
        { text : this.$t('EducationPrograms') , value : 'education_programs_count' , width: 10 , align : 'center' },
        { text : this.$t('StudyPlans')     , value : 'study_plans_count'           , width: 10 , align : 'center' },
        { text : this.$t('Disciplines')    , value : 'disciplines_count'           , width: 10 , align : 'center' },
      ]
    },
    itemsFiltered() {
      let items = this.items

      if ( this.selectedEntityType && this.selectedEntityId ) {
        items = items.filter( el => el[ this.selectedEntityType +  '_id'] === this.selectedEntityId )
      }

      return items
    },
    specializationsSelectFiltered() {
      let items = this.specializationsSelect
      if ( !items ) {
        return []
      }

      if ( !this.entityData.specialty_id) {
        return []
      }

      items = items.filter(el => el.specialty_id === this.entityData.specialty_id )


      return items
    },
    contextActionsSelect() {
      return [
        { text : this.$t('Edit') ,
          value : 'edit' , icon : 'mdi-pencil' ,
          action : () => { this.openEditFaculty() } },
        { text : this.$t('Delete') ,
          value : 'delete' ,
          icon : 'mdi-delete-outline' ,
          action : () => { this.displayDeleteDialog = true }
        }
      ]
    },
    specializationsSlectFiltered() {
      let items = this.specializationsSelect
      if ( !items ) {
        return []
      }

      return items
    },

  },
  methods : {
    ...mapActions('structure' , [
        'GET_SPECIALTIES' ,
        'ADD_SPECIALTY' ,
        'DELETE_SPECIALTY'
    ]),

    selectEntity(item) {
      this.$store.state.structure.returnEntityType.push(this.$store.state.structure.selectedEntityType)
      this.$store.state.structure.returnEntityId.push(this.$store.state.structure.selectedEntityId)
      this.$store.state.structure.returnEntityName.push(this.$store.state.structure.selectedEntityName)

      this.$store.state.structure.selectedEntityType = 'specialty'
      this.$store.state.structure.selectedEntityId = item.uuid
      this.$store.state.structure.selectedEntityName = item.name
      this.$router.push(this.businessDashLink(`structure/disciplines?entity=department&uuid=${item.uuid}&name=${item.name}`))
    },
    // AJAX
    async addEntity() {

      if ( !this.entityData.department_id ) {
        this.departmentError = true
        return this.notify(this.$t('wsu.structure.department.choose') , 'warning')
      }

      if ( !this.entityData.specialty_id ) {
        this.nameError = true
        return this.notify(this.$t('wsu.structure.specialty.name.error') , 'warning')
      }

      this.entityData.lang = this.selectedLang
      let result = await this.ADD_SPECIALTY(this.entityData)
      if ( !result ) {
        this.notify(this.$t('NetworkError'))
        return
      }

      if ( !this.editEntity ) {
        this.items.push(result)
        this.notify(this.$t('wsu.structure.specialty.created') , 'success')
      } else {
        let index = this.items.findIndex(el => el.uuid === result.uuid )
        if (index === -1 ) {
          return
        }
        this.items[index] = result
        this.items = this.COPY(this.items)
        this.notify(this.$t('ChangesSaved'))
      }

      this.displayDialog = false
    },
    async deleteEntity() {
      let result = await this.DELETE_SPECIALTY(this.selectedItem.uuid)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      let index = this.items.findIndex(el => el.uuid === this.selectedItem.uuid )
      if (index === -1 ) {
        return
      }
      this.items.splice(index , 1)
      this.notify(this.$t('wsu.structure.faculty.deleted') )
      this.displayDeleteDialog = false
      this.displayDialog = false
    },
    // Methods
    openEditFaculty() {
      this.entityData = this.COPY(this.selectedItem)
      this.editEntity = true
      this.displayDialog = true
    },
    openNewInstitute() {
      this.editEntity = false
      this.entityData = {}
      this.displayDialog = true
    },
    // Technical
    async initPage() {
      let result = await this.GET_SPECIALTIES()
      this.items = result.specialties || []
      this.departmentsSelect = result.departments_select || []
      this.specialtiesSelect = result.specialties_select || []
      this.specializationsSelect = result.specializations_select || []
      this.degreesSelect = result.degrees_select || []
      this.studyFieldsSelect = result.study_fields_select || []


    },
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>